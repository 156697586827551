export default async function base64ToBlob(base64 = '') {
  let result = null;

  try {
    const response = await fetch(base64);
    result = await response.blob();
  } catch {
    // eslint-disable-next-line no-console
    console.warn('[base64ToBlob]: error');
  }

  return result;
}
