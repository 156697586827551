import isAllKeysExist from 'functions/isAllKeysExist';
import initialState from './initialState';
import * as actionTypes from './actionTypes';

const ACTIONS = Object.values(actionTypes);

export default function reducer(state = initialState, { type, payload } = {}) {
  if (isAllKeysExist(payload, ['key', 'value'])) {
    const { key, value } = payload;

    if (ACTIONS.includes(type)) {
      return { ...state, [key]: value };
    }
  }

  return state;
}
