import { useDispatch } from 'react-redux';
import { IconRefresh } from 'components';
import { paths } from 'router';
import { useNavigate } from 'react-router-dom';
import { commonActions } from 'store/common';

import './ScanAnotherTicketButton.style.scss';

const ScanAnotherTicketButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scanAnotherTicket = async () => {
    navigate(paths.SCAN);
    await dispatch(commonActions.setResult(null));
  };

  return (
    <button
      data-component-name="ScanAnotherTicketButton"
      type="button"
      onClick={scanAnotherTicket}
    >
      <IconRefresh />
      <span>Scan another ticket</span>
    </button>
  );
};

export default ScanAnotherTicketButton;
