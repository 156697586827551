import PropTypes from 'prop-types';
import './Overlay.style.scss';

const Overlay = ({ children, className }) => (
  <div data-component-name="Overlay" className={className}>
    {children}
  </div>
);

Overlay.defaultProps = {
  className: null,
};

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Overlay;
