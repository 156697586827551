import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions, commonSelectors } from 'store/common';
import { paths } from 'router';
import { BLUE_LLAMA_URL } from 'constants';
import './TermsOfUse.style.scss';

// assets
import bg from 'assets/images/bg_1.png';
import bg2x from 'assets/images/bg_1@2x.png';

const TermsOfUse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const termsConditions = useSelector(commonSelectors.selectTermsConditions);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(commonActions.setTerms(true));
    navigate(paths.SCAN);
  };

  return (
    <main data-component-name="TermsOfUse">
      <img src={bg} srcSet={`${bg2x} 2x`} alt="background" className="background" />

      <h1>Terms of Service</h1>

      <form onSubmit={submitHandler}>
        <div
          className="terms-conditions"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: termsConditions }}
        />

        <div className="buttons">
          <a href={BLUE_LLAMA_URL} className="btn decline">
            Decline
          </a>
          <button type="submit" className="btn accept">
            Accept
          </button>
        </div>
      </form>
    </main>
  );
};

export default TermsOfUse;
