import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './NavigationLink.style.scss';

const NavigationLink = ({ to, children } = { to: '/' }) => (
  <Link data-component-name="NavigationLink" to={to}>
    {children}
  </Link>
);

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavigationLink;
