const IconRefresh = () => (
  <svg
    data-component-name="IconRefresh"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20.995"
    fill="white"
    viewBox="0 0 21 20.995"
  >
    <path
      id="Repeat"
      d="M0-19.495a10.457,10.457,0,0,1,9,5.128V-18.75a.751.751,0,0,1,.75-.75.751.751,0,0,1,.75.75v6a.752.752,0,0,1-.75.75h-6A.752.752,0,0,1,3-12.75a.752.752,0,0,1,.75-.75H7.774A8.964,8.964,0,0,0,0-18,9.008,9.008,0,0,0-9-9,9.009,9.009,0,0,0,0,0,8.941,8.941,0,0,0,7.092-3.461.745.745,0,0,1,8.145-3.59a.75.75,0,0,1,.129,1.052A10.451,10.451,0,0,1-.008,1.5,10.5,10.5,0,0,1-10.5-9,10.5,10.5,0,0,1,0-19.495Z"
      transform="translate(10.5 19.5)"
    />
  </svg>
);

export default IconRefresh;
