import { useSelector } from 'react-redux';
import { commonSelectors } from 'store/common';
import { BLUE_LLAMA_URL } from 'constants';
import './Maintenance.style.scss';

// assets
import bg from '../assets/images/bg_5.png';
import bg2x from '../assets/images/bg_5@2x.png';
import balloonsImage from '../assets/images/balloons.png';
import balloonsImage2x from '../assets/images/balloons@2x.png';
import linkImage from '../assets/images/link-new.png';
import linkImage2x from '../assets/images/link-new@2x.png';
import logoImage from '../assets/images/logo.png';
import logoImage2x from '../assets/images/logo@2x.png';

const Maintenance = () => {
  const maintenanceText = useSelector(commonSelectors.selectMaintenanceText);

  return (
    <div data-component-name="Maintenance">
      <img
        src={bg}
        srcSet={`${bg2x} 2x`}
        alt="background"
        className="background"
      />

      <img
        src={balloonsImage}
        srcSet={`${balloonsImage2x} 2x`}
        alt="balloons"
        className="balloons"
        draggable={false}
      />

      <section>
        <a href={BLUE_LLAMA_URL}>
          <img
            src={linkImage}
            srcSet={`${linkImage2x} 2x`}
            alt="link"
            className="link"
            draggable={false}
          />
        </a>

        <p>{maintenanceText}</p>

        <span>Good luck from</span>

        <img
          src={logoImage}
          srcSet={`${logoImage2x} 2x`}
          alt="logo"
          className="logo"
          draggable={false}
        />
      </section>
    </div>
  );
};

export default Maintenance;
