// https://usefulangle.com/post/352/javascript-capture-image-from-camera
// https://developer.chrome.com/blog/mediastream-deprecations/#stop-ended-and-active

// TODO: handle if camera access doesn't provided
// TODO: handle if no codes found
// TODO: handle if more then 2 codes found
// TODO: refactor

import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions, commonSelectors } from 'store/common';
import base64ToBlob from 'functions/base64ToBlob';
import './Scan.style.scss';

// assets
import bg from 'assets/images/bg_2.png';
import bg2x from 'assets/images/bg_2@2x.png';
import emojiFingers from '../assets/images/emoji-fingers.png';
import emojiFingers2x from '../assets/images/emoji-fingers@2x.png';

const Scan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const streamRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const result = useSelector(commonSelectors.selectResult);
  const error = useSelector(commonSelectors.selectError);

  const takePhoto = async () => {
    canvasRef.current
      .getContext('2d')
      .drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    const image = await base64ToBlob(canvasRef.current.toDataURL('image/png'));

    dispatch(commonActions.setLoading(true));
    await dispatch(commonActions.requestVerify(image));
    dispatch(commonActions.setLoading(false));
  };

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: { ideal: 720 },
          height: { ideal: 1280 },
          facingMode: { exact: 'environment' },
        },
      })
      .then((mediaStream) => {
        streamRef.current = mediaStream;
        videoRef.current.srcObject = mediaStream;
      })
      // eslint-disable-next-line
      .catch(console.warn);
  };

  const stopCamera = () => {
    streamRef.current?.getTracks().forEach((track) => track.stop());

    canvasRef.current
      ?.getContext('2d')
      .clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    dispatch(commonActions.setError(null));
  };

  const resetCamera = () => {
    canvasRef.current
      ?.getContext('2d')
      .clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    dispatch(commonActions.setResult(null));
    dispatch(commonActions.setError(null));
  };

  // mount
  useEffect(() => {
    startCamera();

    // unmount
    return () => {
      stopCamera();
    };
  }, []);

  // success scan result
  useEffect(() => {
    if (Array.isArray(result)) {
      stopCamera();
      navigate(paths.RESULT);
    }
  }, [result]);

  // fail scan result
  useEffect(() => {
    if (error === 'No codes found') {
      // eslint-disable-next-line
      alert('Sorry, we were unable to find any ticket numbers. Please try scanning again.');
      resetCamera();
    }
  }, [error]);

  return (
    <main data-component-name="Scan">
      <img src={bg} srcSet={`${bg2x} 2x`} alt="background" className="background" />

      <img
        src={emojiFingers}
        srcSet={`${emojiFingers2x} 2x`}
        alt="fingers emoji"
        className="emoji"
        draggable={false}
      />

      <h1>Check your ticket</h1>

      <p>
        Good Luck from&nbsp;
        <a href="https://bluellama.co.uk/" target="_blank" rel="noreferrer">
          Blue Llama
        </a>
        !
      </p>

      <div className="scanner">
        <video ref={videoRef} autoPlay playsInline allow="camera;microphone" />
        <canvas ref={canvasRef} />

        <div className="camera-view">
          <div className="tl" />
          <div className="tr" />
          <div className="bl" />
          <div className="br" />
        </div>
      </div>

      <strong>
        Position
        {' '}
        <u>both</u>
        {' '}
        numbers within the
        {' '}
        <br />
        above panel then click the button below
      </strong>

      <button type="button" aria-label="take a photo" onClick={takePhoto} />
    </main>
  );
};

export default Scan;
