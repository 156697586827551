import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router';
import './SplashScreen.style.scss';

// assets
import sponsorImage from 'assets/images/channel-islands-lottery.png';
import sponsorImage2x from 'assets/images/channel-islands-lottery@2x.png';
import { logo } from 'lottie';
import crunchingSound from 'assets/audio/crunching.mp3';

// This screen would appear for around four seconds.
// Would be nice to have some subtle fade transitions on this screen.

const SplashScreen = () => {
  const logoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(
    () => {
      const animation = lottie.loadAnimation({
        container: logoRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: logo,
      });

      animation.goToAndPlay(160, true);

      return () => animation.destroy();
    },
    [],
  );

  useEffect(
    () => {
      // FIXME
      const timer = setTimeout(() => {
        navigate(paths.TERMS_OF_USE);
      }, 4000);

      return () => clearTimeout(timer);
    },
    [],
  );

  return (
    <main data-component-view="SplashScreen">
      <div className="author">
        <p className="description">App lovingly crafted in Jersey by</p>
        <div className="logo" ref={logoRef} />

        <audio src={crunchingSound} autoPlay />
      </div>

      <img
        src={sponsorImage}
        srcSet={`${sponsorImage2x} 2x`}
        alt="supported by Channel Islands Lottery"
        draggable={false}
      />
    </main>
  );
};

export default SplashScreen;
