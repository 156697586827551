import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { commonSelectors } from 'store/common';
import {
  SplashScreen,
  Scan,
  TermsOfUse,
  WinningNumbers,
  Instructions,
  Result,
  Maintenance,
  Protected,
  NoCamera,
} from 'pages';
import * as paths from './paths';

const {
  SPLASH_SCREEN,
  SCAN,
  TERMS_OF_USE,
  WINNING_NUMBERS,
  INSTRUCTIONS,
  RESULT,
  MAINTENANCE,
  NO_CAMERA,
} = paths;

const RouterView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const mode = useSelector(commonSelectors.selectMaintenanceMode);
  const result = useSelector(commonSelectors.selectResult);
  const terms = useSelector(commonSelectors.selectTerms);

  const checkForCamera = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return devices.some((device) => device.kind === 'videoinput');
  };

  // Router guards
  useEffect(
    () => {
      const { pathname } = location;

      if ([SPLASH_SCREEN].includes(pathname) && window.innerWidth > 1024) navigate(NO_CAMERA);

      if ([MAINTENANCE].includes(pathname)) {
        if (!mode) navigate(SPLASH_SCREEN);
        // TODO: if page enabled -> not able to navigate to other pages
      }

      if ([SCAN, RESULT, WINNING_NUMBERS, INSTRUCTIONS].includes(pathname)) {
        // "terms of use" is not accepted
        if (!checkForCamera() || window.innerWidth > 1024) navigate(NO_CAMERA);

        if (!terms) navigate(TERMS_OF_USE);
      }

      if ([RESULT].includes(pathname)) {
        // no scanning results
        if (!result) navigate(SPLASH_SCREEN);
      }

      if (!Object.values(paths).some((path) => path === pathname)) {
        // matched route not found
        navigate(SPLASH_SCREEN);
      }
    },
    [location],
  );

  return (
    <Routes>
      <Route path={MAINTENANCE} element={<Maintenance />} />
      <Route path={NO_CAMERA} element={<NoCamera />} />
      <Route
        path="*"
        element={(
          <Protected>
            <Routes>
              <Route index path={SPLASH_SCREEN} element={<SplashScreen />} />
              <Route path={TERMS_OF_USE} element={<TermsOfUse />} />
              <Route path={SCAN} element={<Scan />} />
              { result && <Route path={RESULT} element={<Result />} /> }
              <Route path={WINNING_NUMBERS} element={<WinningNumbers />} />
              <Route path={INSTRUCTIONS} element={<Instructions />} />
            </Routes>
          </Protected>
        )}
      />
    </Routes>
  );
};

export default RouterView;
