import PropTypes from 'prop-types';
import { IconPrize } from 'components';
import './Prize.style.scss';

// assets
import prizeOutlineImage from '../assets/images/prize-outline.png';
import prizeOutlineImage2x from '../assets/images/prize-outline@2x.png';
import gradientGreenImage from '../assets/images/gradient-green.png';
import gradientGreenImage2x from '../assets/images/gradient-green@2x.png';
import gradientPurpleImage from '../assets/images/gradient-purple.png';
import gradientPurpleImage2x from '../assets/images/gradient-purple@2x.png';

const COLOR_TYPES = {
  green: 'green',
  purple: 'purple',
};
const COLORS = {
  [COLOR_TYPES.green]: {
    x1: gradientGreenImage,
    x2: gradientGreenImage2x,
  },
  [COLOR_TYPES.purple]: {
    x1: gradientPurpleImage,
    x2: gradientPurpleImage2x,
  },
};
const numberWithComma = (x) => (
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
);

const Prize = ({ amount, code, color }) => (
  <div data-component-name="Prize">
    <img
      src={prizeOutlineImage}
      srcSet={`${prizeOutlineImage2x} 2x`}
      alt="prize outline"
      className="outline"
      draggable={false}
    />

    <div className="left">
      <img
        src={COLORS[color].x1}
        srcSet={`${COLORS[color].x2} 2x`}
        alt="prize gradient"
        className="gradient"
        draggable={false}
      />

      <ul>
        <li className="icon"><IconPrize /></li>
        <li className="prize-number">1 Prize</li>
        <li className="amount">{`£${numberWithComma(amount)}`}</li>
      </ul>
    </div>

    <div className="right">
      <ul>
        <li className="label">TICKET NO.</li>
        <li className="number">{code}</li>
      </ul>
    </div>
  </div>
);

Prize.defaultProps = {
  color: 'green',
};

Prize.propTypes = {
  amount: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  color: PropTypes.oneOf(Object.values(COLOR_TYPES)),
};

export default Prize;
