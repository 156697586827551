import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prize, ScanAnotherTicketButton } from 'components';
import { commonSelectors, commonActions } from 'store/common';
import map from 'functions/map';
import './WinningNumbers.style.scss';

// assets
import bg from 'assets/images/bg_3.png';
import bg2x from 'assets/images/bg_3@2x.png';

const numberWithComma = (x) => (
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
);

const WinningNumbers = () => {
  const dispatch = useDispatch();

  const tickets = useSelector(commonSelectors.selectTickets);

  // TODO: refactor
  const renderTickets = () => (
    <ul className="sections">
      {map(tickets, (amount, items, idx) => {
        if (idx === Object.keys(tickets).length - 2 || idx === Object.keys(tickets).length - 1) {
          const [{ code }] = items;

          return (
            <li key={code} className="section">
              <Prize amount={amount} code={code} color={idx === Object.keys(tickets).length - 2 ? 'green' : 'purple'} />
            </li>
          );
        }

        return (
          <li key={amount} className="section">
            <span className="count">
              {`${items.length} `}
              Prizess of
              <strong>{` £${numberWithComma(amount)}`}</strong>
            </span>

            <ul className="list">
              <li className="item">TICKET NO.</li>

              {items.map((item) => (
                <li key={item.code} className="item">
                  {item.code}
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );

  useEffect(() => {
    if (!tickets) {
      dispatch(commonActions.requestTickets());
    }
  }, []);

  return (
    <div data-component-name="WinningNumbers">
      <img src={bg} srcSet={`${bg2x} 2x`} alt="background" className="background" />

      <h1>Winning Numbers</h1>

      <ScanAnotherTicketButton />

      <section>
        {tickets && renderTickets()}

      </section>
    </div>
  );
};

export default WinningNumbers;
