import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { commonSelectors, commonActions } from 'store/common';
import { paths } from 'router';
import lottie from 'lottie-web';
import { IconRefresh } from 'components';
import './Result.style.scss';

// assets
import { confetti } from 'lottie';
import bg1 from 'assets/images/bg_1.png';
import bg1x2 from 'assets/images/bg_1@2x.png';
import bg2 from 'assets/images/bg_2.png';
import bg2x2 from 'assets/images/bg_2@2x.png';
import emojiSad from 'assets/images/emoji-sad.png';
import emojiSad2x from 'assets/images/emoji-sad@2x.png';
import emojiParty from 'assets/images/emoji-party.png';
import emojiParty2x from 'assets/images/emoji-party@2x.png';

const Result = () => {
  const dispatch = useDispatch();

  const confettiRef = useRef(null);
  const result = useSelector(commonSelectors.selectResult);
  const navigate = useNavigate();
  const isCodesLosing = result.every((code) => code.amount === null);

  const numberWithComma = (x) => (
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );

  const renderWonAmount = () => {
    const WORDS = ['You’ve won ', ' and '];

    return result.reduce(
      (acc, code, idx) => {
        if (code.amount && idx === 0) {
          acc.push(
            WORDS[0],
            <strong key={WORDS[0]} className="amount">{`£${numberWithComma(code.amount)}`}</strong>,
          );
        } else if (code.amount && idx === 1 && acc.length > 0) {
          acc.push(
            WORDS[1],
            <strong key={WORDS[1]} className="amount">{`£${numberWithComma(code.amount)}`}</strong>,
          );
        } else if (code.amount && idx === 1 && acc.length < 1) {
          acc.push(
            WORDS[0],
            <strong key={WORDS[0]} className="amount">{`£${numberWithComma(code.amount)}`}</strong>,
          );
        }

        return acc;
      },
      [],
    );
  };

  const renderCodes = () => result.map((code) => (
    <li
      key={code.code}
      className={classNames({
        lose: code.amount === null,
        won: code.amount !== null,
        hn: code.code === null,
      })}
    >
      {code.code}
    </li>
  ));

  const scanAnotherTicket = async () => {
    navigate(paths.SCAN);
    await dispatch(commonActions.setResult(null));
  };

  useEffect(
    () => {
      if (!isCodesLosing) {
        lottie.loadAnimation({
          container: confettiRef.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: confetti,
        });
      }
    },
    [],
  );

  return (
    <div data-component-name="Result">
      <div ref={confettiRef} className="confetti" />

      <img
        src={isCodesLosing ? bg1 : bg2}
        srcSet={`${isCodesLosing ? bg1x2 : bg2x2} 2x`}
        alt="background"
        className="background"
      />

      <img
        src={isCodesLosing ? emojiSad : emojiParty}
        srcSet={`${isCodesLosing ? emojiSad2x : emojiParty2x} 2x`}
        alt={isCodesLosing ? 'sad' : 'party'}
        className="emoji"
      />

      <strong className="reaction">
        {
          isCodesLosing
            ? 'Sorry'
            : 'Whoop Whoop!'
        }
      </strong>

      <h1>
        {
          isCodesLosing
            ? 'This isn’t a winning ticket.'
            : 'Congratulations, your ticket is a winner!'
        }
      </h1>

      {!isCodesLosing && <p className="won-amount">{renderWonAmount()}</p>}

      <ul className={classNames({ 'all-lose': isCodesLosing })}>
        {renderCodes()}
      </ul>

      <p className="warning">Please check your numbers scanned correctly.</p>

      <button
        className="scan"
        type="button"
        onClick={scanAnotherTicket}
      >
        <IconRefresh />
        <span>Scan another ticket</span>
      </button>
    </div>
  );
};

export default Result;
