export default {
  loading: false,
  result: null,
  error: null,
  tickets: null,
  terms: false,
  maintenanceMode: false,
  maintenanceText: '',
  termsConditions: '',
};
