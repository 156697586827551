import { useEffect, useState, useRef } from 'react';
import { axios } from 'api';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { commonSelectors } from 'store/common';
import { MAINTENANCE } from 'router/paths';

// assets
import sponsorImage from 'assets/images/channel-islands-lottery.png';
import sponsorImage2x from 'assets/images/channel-islands-lottery@2x.png';
import crunchingSound from 'assets/audio/crunching.mp3';

const Protected = ({ children }) => {
  const mode = useSelector(commonSelectors.selectMaintenanceMode);
  const [isLoading, setLoading] = useState(true);
  const [ip, setIP] = useState('');
  const IPS = ['89.115.117.6', '89.34.99.131', '178.133.132.231', '87.244.115.4', '99.99.99.99'];
  const logoRef = useRef(null);

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then((response) => {
      setIP(response.data.IPv4);
      setLoading(false);
    });
  }, []);

  if (mode && isLoading) {
    return (
      <main data-component-view="SplashScreen">
        <div className="author">
          <p className="description">App lovingly crafted in Jersey by</p>
          <div className="logo" ref={logoRef} />

          <audio src={crunchingSound} autoPlay />
        </div>

        <img
          src={sponsorImage}
          srcSet={`${sponsorImage2x} 2x`}
          alt="supported by Channel Islands Lottery"
          draggable={false}
        />
      </main>
    );
  }
  if (mode && IPS.indexOf(ip) === -1) {
    return <Navigate to={MAINTENANCE} replace />;
  }

  return children;
};

Protected.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Protected;
