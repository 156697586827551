import './Spinner.style.scss';
import loader from 'assets/images/loader.svg';

const Spinner = () => (
  <img
    src={loader}
    alt="loader"
    draggable={false}
  />
);

export default Spinner;
