import bg from '../assets/images/bg_2.png';
import bg2x from '../assets/images/bg_2@2x.png';
import emoji from '../assets/images/emoji-sad2.png';
import emoji2x from '../assets/images/emoji-sad2@2x.png';
import logoImage from '../assets/images/logo.png';
import logoImage2x from '../assets/images/logo@2x.png';

import './NoCamera.style.scss';

const NoCamera = () => (
  <div data-component-name="NoCamera">
    <img src={bg} srcSet={`${bg2x} 2x`} alt="background" className="background" />

    <section>
      <img src={emoji} srcSet={`${emoji2x} 2x`} alt="sad emoji" className="emoji" />

      <h1>Our lottery checking app only works on mobile devices with a camera.</h1>
      <h2>Please open this page on your phone.</h2>

      <span>App lovingly crafted in Jersey by</span>

      <img
        src={logoImage}
        srcSet={`${logoImage2x} 2x`}
        alt="logo"
        className="logo"
        draggable={false}
      />
    </section>
  </div>
);

export default NoCamera;
