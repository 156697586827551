import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouterView, paths } from 'router';
import { Navigation, Overlay, Spinner } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { commonSelectors, commonActions } from 'store/common';
import classNames from 'classnames';
import './App.style.scss';

const {
  SCAN,
  TERMS_OF_USE,
  WINNING_NUMBERS,
  INSTRUCTIONS,
  RESULT,
} = paths;

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const NAVIGATION_LINKS = [
    {
      to: TERMS_OF_USE,
      label: 'T&Cs',
    },
    {
      to: INSTRUCTIONS,
      label: 'Instructions',
    },
    {
      to: WINNING_NUMBERS,
      label: 'Winning Numbers',
    },
  ];

  const isNavigationVisible = useMemo(
    () => [SCAN, RESULT].includes(location.pathname),
    [location.pathname],
  );
  const isLoading = useSelector(commonSelectors.selectLoading);

  const setFullHeightHandler = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  };

  useEffect(
    () => {
      dispatch(
        commonActions.requestSettings(),
      );

      document.addEventListener('resize', setFullHeightHandler);

      return () => {
        document.removeEventListener('resize', setFullHeightHandler);
      };
    },
    [],
  );

  return (
    <div data-component-name="App">
      <RouterView />
      {isNavigationVisible && <Navigation links={NAVIGATION_LINKS} />}

      <Overlay className={classNames({ visible: isLoading })}>
        <Spinner />
      </Overlay>
    </div>
  );
};

export default App;
