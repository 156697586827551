import PropTypes from 'prop-types';
import { NavigationLink } from 'components';
import './Navigation.style.scss';

const Navigation = ({ links, className }) => {
  const renderLinks = () => links.map((link) => (
    <NavigationLink to={link.to} key={link.to}>
      {link.label}
    </NavigationLink>
  ));

  return (
    <nav data-component-name="Navigation" className={className}>
      {renderLinks()}
    </nav>
  );
};

Navigation.defaultProps = {
  className: null,
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default Navigation;
