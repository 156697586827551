import getType from './getType';

export default function map(items, fn = () => {}) {
  const type = getType(items);

  switch (type) {
    case 'Array':
      return items.map(fn);
    case 'Object':
      return Object.keys(items).map((key, idx) => fn(key, items[key], idx));

    default:
      throw new Error(`Expect: Array, Object. Got: ${type}`);
  }
}
