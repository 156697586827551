import { axios, endpoints } from 'api';
import {
  SET_LOADING,
  SET_RESULT,
  SET_ERROR,
  SET_TICKETS,
  SET_TERMS,
  SET_TERMS_CONDITIONS_TEXT,
  SET_MAINTENANCE_MODE,
} from './actionTypes';

export const setLoading = (value) => ({
  type: SET_LOADING,
  payload: { key: 'loading', value },
});

export const setResult = (value) => ({
  type: SET_RESULT,
  payload: { key: 'result', value },
});

export const setTickets = (value) => ({
  type: SET_TICKETS,
  payload: { key: 'tickets', value },
});

export const setError = (value) => ({
  type: SET_ERROR,
  payload: { key: 'error', value },
});

export const setTerms = (value) => ({
  type: SET_TERMS,
  payload: { key: 'terms', value },
});

export const setTermsConditions = (value) => ({
  type: SET_TERMS_CONDITIONS_TEXT,
  payload: { key: 'termsConditions', value },
});

export const setMaintenanceMode = (value) => ({
  type: SET_MAINTENANCE_MODE,
  payload: { key: 'maintenanceMode', value },
});

export const setMaintenanceText = (value) => ({
  type: SET_MAINTENANCE_MODE,
  payload: { key: 'maintenanceText', value },
});

// TODO: exclude requests?
export const requestVerify = (file) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      endpoints.VERIFY,
      { file },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    dispatch(setResult(data));
  } catch (error) {
    dispatch(setError(error.response.data));
  }
};

export const requestTickets = () => async (dispatch) => {
  try {
    const { data } = await axios.get(endpoints.TICKETS);

    dispatch(setTickets(data));
  } catch (error) {
    // TODO: handle
    // eslint-disable-next-line
    console.warn(error);
  }
};

export const requestSettings = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const { data } = await axios.get(endpoints.SETTINGS);
    const { termsConditions, maintenanceMode, maintenanceText } = data;

    dispatch(setMaintenanceMode(maintenanceMode));
    dispatch(setMaintenanceText(maintenanceText));
    dispatch(setTermsConditions(termsConditions));
  } catch (error) {
    // TODO: handle
    // eslint-disable-next-line
    console.warn(error);
  } finally {
    dispatch(setLoading(false));
  }
};
