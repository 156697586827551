import { ScanAnotherTicketButton } from 'components';
import './Instructions.style.scss';

// assets
import bg1 from '../assets/images/bg_4.png';
import bg1x2 from '../assets/images/bg_4@2x.png';
import ticketImage from '../assets/images/ticket.png';
import ticketImage2x from '../assets/images/ticket@2x.png';

const Instructions = () => (
  <div data-component-name="Instructions">
    <img
      src={bg1}
      srcSet={`${bg1x2} 2x`}
      alt="background"
      className="background"
    />

    <h1>App Instructions</h1>

    <div className="instractions-container">
      <ol>
        <li>
          <p>Allow app access to your camera</p>
        </li>

        <li>
          <p>Place one lottery ticket on a flat surface, numbers side up.</p>
        </li>

        <li>
          <p>
            Point your phone’s camera at the ticket so both ticket numbers are
            within the app’s viewfinder as follows then click the big round
            button.
          </p>

          <img
            src={ticketImage}
            srcSet={`${ticketImage2x} 2x`}
            alt="background"
            className="ticket"
            draggable={false}
          />
        </li>

        <li>
          <p>
            The app will check the detected numbers against the winning numbers
            and let you know if you’ve won.
          </p>
        </li>

        <li>
          <p>
            Check the numbers on this screen against the numbers on your ticket to
            make sure the ticket has been read correctly. If not, scan the ticket
            again.
          </p>
        </li>
      </ol>

      <ScanAnotherTicketButton />
    </div>
  </div>
);

export default Instructions;
